import { lastValueFrom } from 'rxjs';
import { transformDataFrame, DataFrame } from '@grafana/data';

const attributionTransformations = (dimension: string, label: string) => [
  {
    id: 'groupBy',
    options: {
      fields: {
        BillableSeries: {
          aggregations: ['sum'],
          operation: 'aggregate',
        },
        'Month(ISO)': {
          aggregations: [],
          operation: 'groupby',
        },
        [dimension]: {
          aggregations: [],
          operation: 'groupby',
        },
      },
    },
  },
  {
    id: 'groupBy',
    options: {
      fields: {
        'BillableSeries (sum)': {
          aggregations: ['first'],
          operation: 'aggregate',
        },
        [dimension]: {
          aggregations: [],
          operation: 'groupby',
        },
      },
    },
  },
  {
    id: 'organize',
    options: {
      excludeByName: {},
      includeByName: {},
      indexByName: {},
      renameByName: {
        'BillableSeries (sum) (first)': 'Metrics (billable series)',
        [dimension]: `Label: ${label}`,
      },
    },
  },
  {
    id: 'sortBy',
    options: {
      fields: {},
      sort: [
        {
          field: 'Metrics (billable series)',
          desc: true,
        },
      ],
    },
  },
];

export const transformer = async (df: DataFrame[], dimension: string, label: string) => {
  return await lastValueFrom(transformDataFrame(attributionTransformations(dimension, label), df));
};
