import type { PanelData, TimeRange } from '@grafana/data';

export function doneStateCheck(dataPanels: Array<PanelData | undefined>) {
  return dataPanels.every((panel) => panel?.state === 'Done');
}

export function loadingStateCheck(dataPanels: Array<PanelData | undefined>, timeRange?: TimeRange) {
  if (timeRange) {
    // check if the time range has been updated but the query and results have not refreshed yet
    return dataPanels.some((panel) => panel?.state === 'Loading' || !panel?.timeRange.from.isSame(timeRange.from));
  }
  return dataPanels.some((panel) => !panel || panel?.state === 'Loading');
}

export function checkEmptyDataSeries(dataPanel: PanelData | undefined) {
  const noSeries = !dataPanel?.series?.length;
  const noFields = dataPanel?.series?.length === 1 && dataPanel.series[0].fields?.length === 0;
  return noSeries || noFields;
}
