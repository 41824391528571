import { parse, getYear, format } from 'date-fns';
import { cloneDeep, isEmpty } from 'lodash';
import { DataFrame, FieldType, getValueFormat, PanelData, Field, type DateTime } from '@grafana/data';
import { Attribution, DimLabel } from 'types';
import { transformer } from 'transformations/attributions';

import {
  BILLABLE_SERIES,
  FIELD_NAME_APPROXIMATE_COST,
  FIELD_NAME_BILLABLE_METRIC,
  FIELD_NAME_BYTES,
  FIELD_NAME_VALUE,
  UNATTRIBUTED,
} from 'constants/constant';
import { emptyBillableDataframe } from './utils.defaults';
import type { PartialData } from '../pages/Overview/hooks/overview-data';

export const formatTitleDate = (records: Attribution[]) => {
  const lastMonth = findLastMonth(records)?.['Month(ISO)'] ?? null;

  const date = lastMonth ? parse(lastMonth, 'yyyy-MM', new Date()) : null;
  if (!date) {
    return 'this month';
  }
  const year = getYear(date);
  const monthName = format(date, 'MMMM');

  return `${monthName} ${year}`;
};

// Find last month in 3 months of data 'Month(ISO)' no matter what order is returned from the api
export const findLastMonth = (arr: Attribution[]) => {
  if (arr.length === 0) {
    return null;
  }

  return arr.reduce((earliest, current) => {
    const latestDate = new Date(earliest['Month(ISO)']);
    const currentDate = new Date(current['Month(ISO)']);

    return currentDate > latestDate ? current : earliest;
  });
};

// Filter Attribution data to only the last months worth of data
export const filterSelectedMonth = (data: Attribution[], selectedMonth: DateTime) => {
  const isoSelectedMonth = selectedMonth.format('yyyy-MM');
  const dataMatchingMonth = data.filter((row) => {
    return row['Month(ISO)'] === isoSelectedMonth;
  });

  return dataMatchingMonth;
};

const currencyFormatter = getValueFormat('currencyUSD');

export const unFormatDollarCost = (value: number, seriesSum: number, total: number) => {
  seriesSum = isNaN(seriesSum) ? 0 : seriesSum;

  // No data return total bill as zero
  if (seriesSum === 0) {
    return seriesSum;
  }

  return parseFloat(((value / seriesSum) * total).toFixed(2));
};

export const seriesBytesPerBillingBytes = (value: number, seriesSum: number, total: number) => {
  seriesSum = isNaN(seriesSum) ? 0 : seriesSum;

  // No data return total bill as zero
  if (seriesSum === 0) {
    return seriesSum;
  }
  // Billing series totals convert to gigabytes
  const totalBytes = total * 1024 * 1024 * 1024;

  return (value / seriesSum) * totalBytes;
};

export const formatDollarCost = (dollarValue: number) => {
  const { prefix, text, suffix } = currencyFormatter(dollarValue);
  return `${prefix}${text}${suffix}`;
};
export const bytesFormatter = getValueFormat('bytes');

export const bytesFormat = (value: number) => {
  const { text, suffix } = bytesFormatter(value);
  return `${text}${suffix}`;
};

export const shortFormatter = getValueFormat('short');
export const shortFormat = (value: number) => {
  const { text, suffix } = shortFormatter(value);
  return `${text}${suffix}`;
};

// Get total panel cost number
const getTotalCost = (fields: Field[] | null) => {
  if (fields) {
    for (const field of fields) {
      if (field.name === FIELD_NAME_VALUE) {
        const total = field.values[field.values.length - 1];
        return Number.isNaN(total) ? 0 : total;
      }
    }
  }
  return 0;
};

const getAllLabelValuesPanelData = (pd: PanelData, label: string) => {
  const allLabels: string[] = [];
  pd.series.forEach((series, i) => {
    series.fields.forEach((field) => {
      if (field.name === FIELD_NAME_VALUE) {
        if (field.labels) {
          if (field.labels[label]) {
            allLabels.push(field.labels[label]);
          } else {
            // an empty label string will get grouped into the 'unattributed' category
            allLabels.push('');
          }
        }
      }
    });
  });
  return allLabels;
};

export const createBillableSeriesFieldsFromLabelMatching = (allLabelFields: Field, pd: PanelData) => {
  const billableSeriesValues: number[] = Array.from({ length: allLabelFields.values.length }, () => 0);
  const emptyLabelIndex = allLabelFields.values.findIndex((label) => label === '');

  // Match trace label values with metric label values and return the log billable series values for that label value
  allLabelFields.values.forEach((labelValue, i) => {
    pd.series.forEach((series) => {
      series.fields.forEach((field) => {
        if (field.name === FIELD_NAME_VALUE) {
          // Match by empty labels, set with empty label index
          if (isEmpty(field.labels)) {
            billableSeriesValues[emptyLabelIndex] = field.values[field.values.length - 1];
          }
          // Match by label values
          if (field.labels) {
            Object.values(field.labels).forEach((fieldLabelValue: string) => {
              // check for any label value matches and update the corresponding index in the metricsLabelValues array
              if (labelValue === fieldLabelValue) {
                billableSeriesValues[i] = field.values[field.values.length - 1];
              }
            });
          }
        }
      });
    });
  });
  return billableSeriesValues;
};
export interface TransformOptions {
  metricAttributions: Attribution[];
  metricAttributionsFrame: DataFrame;
  metricTotalBillPanelData: PanelData | undefined;
  logsAttributionData: PanelData;
  logsAttributionDataTotal: PanelData;
  logsTotalBillPanelData: PanelData;
  logsBillingDataTotal: PanelData;
  tracesTotalBillPanelData: PanelData;
  tracesAttributionData: PanelData;
  tracesAttributionDataTotal: PanelData;
  tracesBillingDataTotal: PanelData;
  isPartialData: PartialData;
  isCurrentMonth: boolean;
  attributionLabel: string;
}

export const formatTransformDollars = async (
  options: TransformOptions
): Promise<{ tableData: DataFrame[][]; csvData: DataFrame[][] }> => {
  const {
    metricAttributions,
    metricAttributionsFrame,
    metricTotalBillPanelData,
    logsTotalBillPanelData,
    logsAttributionData,
    logsAttributionDataTotal,
    logsBillingDataTotal,
    tracesTotalBillPanelData,
    tracesAttributionData,
    tracesAttributionDataTotal,
    tracesBillingDataTotal,
    isPartialData,
    isCurrentMonth,
    attributionLabel,
  } = options;

  if (
    !metricTotalBillPanelData ||
    !metricAttributions ||
    !metricAttributionsFrame ||
    !logsAttributionData ||
    !logsTotalBillPanelData ||
    !tracesTotalBillPanelData ||
    !tracesAttributionData
  ) {
    throw new Error('Required data is missing');
  }

  const isMetrics = metricAttributions?.length > 0 || false;
  const isLogs = logsAttributionData?.series[0]?.fields.length > 0 || false;
  const isTraces = tracesAttributionData?.series[0]?.fields.length > 0 || false;

  // FORMAT METRICS
  // Transform Metrics data
  let transformedData = [[emptyBillableDataframe(attributionLabel)]];
  if (!isCurrentMonth && isMetrics) {
    transformedData = await Promise.all(
      [`label_${attributionLabel}` as DimLabel].map(async (dimension: DimLabel) => {
        return transformer(
          [addFieldForLabelIfMissing(metricAttributionsFrame, dimension)],
          dimension,
          attributionLabel
        );
      })
    );
  }

  // Get all label values from from metrics, logs, traces
  transformedData.forEach((dataFrame) => {
    const df = dataFrame[0];
    const [labelsField] = df.fields.filter((field) => field.name !== FIELD_NAME_BILLABLE_METRIC);
    // Set table column header for attribution label
    if (labelsField.state) {
      labelsField.state.displayName = `Attribution per label "${attributionLabel}"`;
    }
    addMissingLabelValuesToDataframe(dataFrame, logsAttributionData, tracesAttributionData, attributionLabel);
  });

  // Clone the transformedData object to output table and csv
  const tableData = cloneDeep(transformedData);
  const csvData = cloneDeep(transformedData);

  // Get total metrics cost
  let totalMetricsBill = getTotalCost(metricTotalBillPanelData?.series[0]?.fields);

  // Calculate sum of Metric billable series
  const metricsBillableSeriesSum = transformedData
    .flat()
    .flatMap((frames) => frames.fields)
    .filter((field) => field.name === FIELD_NAME_BILLABLE_METRIC)
    .flatMap((field) => field.values)
    .reduce((acc, value) => acc + (Number.isInteger(value) ? value : 0), 0);

  // Eventually multiple labels will be present in the DataFrame
  transformedData.forEach((dataFrame, i) => {
    const df = dataFrame[0];
    const tableDF = tableData[i][0];
    const csvDF = csvData[i][0];
    const billableField = df.fields.find((field) => field.name === FIELD_NAME_BILLABLE_METRIC);
    const [labelFields] = df.fields.filter((field) => field.name !== FIELD_NAME_BILLABLE_METRIC);
    const [tableLabelFields] = tableDF.fields.filter((field) => field.name !== FIELD_NAME_BILLABLE_METRIC);
    const [csvLabelFields] = csvDF.fields.filter((field) => field.name !== FIELD_NAME_BILLABLE_METRIC);
    let totalCostMetrics: number[] = [];
    let totalCostLogs: number[] = [];
    let totalCostTraces: number[] = [];

    // METRICS
    if (billableField) {
      // Billable Series Dollar values converted to currency
      billableField.values.map((value) => {
        const dollarValue = unFormatDollarCost(value, metricsBillableSeriesSum, totalMetricsBill);
        totalCostMetrics.push(dollarValue);
        return formatDollarCost(dollarValue);
      });

      // Metrics Billable Series values converted to short
      billableField.values = billableField.values.map((value) => {
        return shortFormat(value);
      });

      // CSV Add Metrics approximate dollar cost to DataFrame
      if (!isCurrentMonth && isMetrics) {
        csvDF.fields.push({
          name: FIELD_NAME_APPROXIMATE_COST('Metrics'),
          type: FieldType.number,
          config: { max: Math.max(...totalCostMetrics) },
          values: totalCostMetrics,
        });

        // Table add Metrics cost to DataFrame
        tableDF.fields.push({
          name: FIELD_NAME_APPROXIMATE_COST('Metrics'),
          type: FieldType.number,
          config: { max: Math.max(...totalCostMetrics) },
          values: totalCostMetrics,
        });
      }
    }

    // Format Metrics empty label fields with unattributed
    csvLabelFields.values.forEach((value, i) => {
      csvLabelFields.values[i] = value === '' ? UNATTRIBUTED : value;
    });
    tableLabelFields.values.forEach((value, i) => {
      tableLabelFields.values[i] = value === '' ? UNATTRIBUTED : value;
    });

    // FORMAT LOGS
    if (isLogs) {
      // Create Logs billable series values from matching labels
      const logsBillableSeriesValues = createBillableSeriesFieldsFromLabelMatching(labelFields, logsAttributionData);

      // Calculate portion of bytes as a portional to the total GiB from the billing dashboard
      // Attribution total bytes
      const logsAttributionDataTotalValue = logsAttributionDataTotal.series[0].fields.find((field) => {
        return field.name === FIELD_NAME_VALUE;
      })?.values[0];

      // Billing dashboard total GiB
      const logsBillingDataTotalValue = logsBillingDataTotal.series[0].fields.find((field) => {
        return field.name === 'grafanacloud_logs_instance_usage';
      })?.values[0];

      // Logs proportional series values
      const logsProportionalSeriesValues = logsBillableSeriesValues.map((value) => {
        return seriesBytesPerBillingBytes(value, logsAttributionDataTotalValue, logsBillingDataTotalValue);
      });

      // Full data set use proportional data for logs to match the billing dashboard
      if (!isPartialData.isLogsPartial) {
        // CSV add logs proportional bytes to DataFrame
        csvDF.fields.push({
          name: FIELD_NAME_BYTES('Logs', isPartialData.isLogsPartial),
          type: FieldType.number,
          config: {},
          values: logsProportionalSeriesValues,
        });

        // Table add logs proportional bytes to DataFrame
        tableDF.fields.push({
          name: FIELD_NAME_BYTES('Logs', isPartialData.isLogsPartial),
          type: FieldType.number,
          config: {},
          values: logsProportionalSeriesValues,
        });
      } else {
        // Partial data use raw api data for logs
        // CSV add Logs Billable series to DataFrame
        csvDF.fields.push({
          name: FIELD_NAME_BYTES('Logs', isPartialData.isLogsPartial),
          type: FieldType.number,
          config: {},
          values: logsBillableSeriesValues,
        });

        // Table add Logs Billable series to DataFrame
        tableDF.fields.push({
          name: FIELD_NAME_BYTES('Logs', isPartialData.isLogsPartial),
          type: FieldType.number,
          config: {},
          values: logsBillableSeriesValues,
        });
      }

      // Get Total logs bill
      const totalLogsBill = getTotalCost(logsTotalBillPanelData.series[0].fields);

      // Calculate sum of logs billable series
      const totalLogsBillableSum = logsBillableSeriesValues.reduce((acc, value) => acc + value, 0);

      // Calculate dollar cost of billable series as a portional to the sum
      logsBillableSeriesValues.map((value) => {
        const dollarValue = unFormatDollarCost(value, totalLogsBillableSum, totalLogsBill);
        totalCostLogs.push(dollarValue);
        return formatDollarCost(dollarValue);
      });

      // Add total cost and dollars only for full dataset
      if (!isPartialData.isLogsPartial) {
        // Table add Logs dollar cost to DataFrame
        tableDF.fields.push({
          name: FIELD_NAME_APPROXIMATE_COST('Logs'),
          type: FieldType.number,
          config: { max: Math.max(...totalCostLogs) },
          values: totalCostLogs,
        });
        csvDF.fields.push({
          name: FIELD_NAME_APPROXIMATE_COST('Logs'),
          type: FieldType.number,
          config: { max: Math.max(...totalCostLogs) },
          values: totalCostLogs,
        });
      }
    }

    if (isTraces) {
      // Create traces billable series values from matching labels
      const tracesBillableSeriesValues = createBillableSeriesFieldsFromLabelMatching(
        labelFields,
        tracesAttributionData
      );

      // Calculate portion of bytes as a portional to the total GiB from the billing dashboard
      // Attribution total bytes
      const tracesAttributionDataTotalValue = tracesAttributionDataTotal.series[0].fields.find((field) => {
        return field.name === FIELD_NAME_VALUE;
      })?.values[0];

      // Billing dashboard total GiB
      const tracesBillingDataTotalValue = tracesBillingDataTotal.series[0].fields.find((field) => {
        return field.name === 'grafanacloud_traces_instance_usage';
      })?.values[0];

      // Logs proportional series values
      const tracesProportionalSeriesValues = tracesBillableSeriesValues.map((value) => {
        return seriesBytesPerBillingBytes(value, tracesAttributionDataTotalValue, tracesBillingDataTotalValue);
      });

      // Full data set use proportional data for traces to match the billing dashboard
      if (!isPartialData.isTracesPartial) {
        // CSV add proportioanl traces bytes to DataFrame
        csvDF.fields.push({
          name: FIELD_NAME_BYTES('Traces', isPartialData.isTracesPartial),
          type: FieldType.number,
          config: {},
          values: tracesProportionalSeriesValues,
        });

        // Table add proportioanl traces bytes to DataFrame
        tableDF.fields.push({
          name: FIELD_NAME_BYTES('Traces', isPartialData.isTracesPartial),
          type: FieldType.number,
          config: {},
          values: tracesProportionalSeriesValues,
        });
      } else {
        // Partial data set use raw api bytes data for traces
        // CSV add traces raw api data  to DataFrame
        csvDF.fields.push({
          name: FIELD_NAME_BYTES('Traces', isPartialData.isTracesPartial),
          type: FieldType.number,
          config: {},
          values: tracesBillableSeriesValues,
        });
        // Table add traces raw api bytes data to DataFrame
        tableDF.fields.push({
          name: FIELD_NAME_BYTES('Traces', isPartialData.isTracesPartial),
          type: FieldType.number,
          config: {},
          values: tracesBillableSeriesValues,
        });
      }

      // Get Total traces bill
      const totalTracesBill = getTotalCost(tracesTotalBillPanelData.series[0].fields);

      // Calculate sum of traces billable series
      const totalTracesBillableSum = tracesBillableSeriesValues.reduce((acc, value) => acc + value, 0);

      // Calculate dollar cost of billable series as a portional to the sum
      tracesBillableSeriesValues.forEach((value) => {
        const dollarValue = unFormatDollarCost(value, totalTracesBillableSum, totalTracesBill);
        totalCostTraces.push(dollarValue);
        return formatDollarCost(dollarValue);
      });

      // Add total cost and dollars only for full dataset
      if (!isPartialData.isTracesPartial) {
        // Table add traces cost to DataFrame
        tableDF.fields.push({
          name: FIELD_NAME_APPROXIMATE_COST('Traces'),
          type: FieldType.number,
          config: { max: Math.max(...totalCostTraces) },
          values: totalCostTraces,
        });
        // CSV add proportioanl traces bytes to DataFrame
        csvDF.fields.push({
          name: FIELD_NAME_APPROXIMATE_COST('Traces'),
          type: FieldType.number,
          config: { max: Math.max(...totalCostTraces) },
          values: totalCostTraces,
        });
      }
    }

    // Total Cost column
    // Totals can only be calculated if all the data is present in totalCostMetrics totalCostLogs totalCostTraces
    const isDifferentLength =
      totalCostMetrics.length !== totalCostLogs.length || totalCostMetrics.length !== totalCostTraces.length;

    if (isDifferentLength) {
      // Ensure all arrays have the same length
      if (totalCostMetrics.length !== totalCostLogs.length || totalCostMetrics.length !== totalCostTraces.length) {
        console.warn('All arrays must have the same length');
      }
    }

    const noPartialData = Object.values(isPartialData).every((value) => !value);
    if (totalCostMetrics.length > 0 && !isDifferentLength && noPartialData) {
      let totalCostValuesFormatted: string[] = [];
      let totalCostValues: number[] = [];

      // Iterate through the arrays and sum the corresponding values
      for (let i = 0; i < totalCostMetrics.length; i++) {
        totalCostValues.push(totalCostMetrics[i] + totalCostLogs[i] + totalCostTraces[i]);
        totalCostValuesFormatted.push(formatDollarCost(totalCostMetrics[i] + totalCostLogs[i] + totalCostTraces[i]));
      }

      // Table Add total cost dollars per label value to DataFrame
      tableDF.fields.push({
        name: 'Total cost',
        type: FieldType.number,
        config: {},
        values: totalCostValues,
      });
    }

    // Update whole dataFrame length to display all values in the table
    csvDF.length = csvLabelFields.values.length;
    tableDF.length = tableLabelFields.values.length;
  });

  return { tableData, csvData };
};

// If the traces or logs have additional label values to what metrics has, add them to the metrics dataframe
function addMissingLabelValuesToDataframe(
  dataFrame: DataFrame[],
  logsAttributionData: PanelData,
  tracesAttributionData: PanelData,
  attributionLabel: string
) {
  const df = dataFrame[0];
  const [metricsLabelsField] = df.fields.filter((field) => field.name !== FIELD_NAME_BILLABLE_METRIC);
  const [metricsBillableField] = df.fields.filter((field) => field.name === FIELD_NAME_BILLABLE_METRIC);

  const allLogsLabelValues = getAllLabelValuesPanelData(logsAttributionData, attributionLabel);
  const allTraceLabelValues = getAllLabelValuesPanelData(tracesAttributionData, attributionLabel);

  const allLabelValues = metricsLabelsField.values.concat(allLogsLabelValues).concat(allTraceLabelValues);
  metricsLabelsField.values = Array.from(new Set(allLabelValues));

  // If new label values were added to metricsLabelsField, add 0 to metricsBillableField
  if (metricsBillableField && metricsLabelsField.values.length > metricsBillableField.values.length) {
    const diff = metricsLabelsField.values.length - metricsBillableField.values.length;
    for (let i = 0; i < diff; i++) {
      metricsBillableField.values.push(0);
    }
  }
}

// It's possible the currently configured attribution label is not present in the metricAttributionsFrame
// If that's the case, grouping by the label will end up with null results, so add the label with 'unattributed' values instead
function addFieldForLabelIfMissing(metricAttributionsFrame: DataFrame, dimension: DimLabel) {
  const hasLabel = metricAttributionsFrame.fields.find((field) => field.name === dimension);
  if (!hasLabel) {
    const df = metricAttributionsFrame.fields.find((field) => field.name === BILLABLE_SERIES);
    metricAttributionsFrame.fields.push({
      name: dimension,
      config: {},
      type: FieldType.string,
      values: df?.values.map(() => '') || [],
    });
  }
  return metricAttributionsFrame;
}
