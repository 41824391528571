import React, { useEffect, useState } from 'react';
import { Input, FieldSet, Field, FieldValidationMessage, Alert } from '@grafana/ui';
import { testIds } from 'components/testIds';
import { useStyles } from 'styles';
import { useConfigContext } from 'context/ConfigContext';
import { useGetLabels, usePostLabels, usePutLabels } from 'hooks/configuration';
import { Controller, useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { CostAttributionLabelName } from 'generated/costattributionlabelname/v1/costattributionlabelname_object_gen';
import { CostAttributionLabelNameListResponse } from 'types';

export const ConfigurationPage = () => {
  const styles = useStyles();
  const { metricLabel, setMetricLabelValue } = useConfigContext();
  const [initialLabel, setInitialLabel] = useState<string | null>(null);
  const [apiError, setAppError] = useState<string | null>(null);
  const { data } = useGetLabels();
  const { mutate: postMutate, isError: IsPostError } = usePostLabels();
  const { mutate: putMutate, isError: IsPutError } = usePutLabels();
  const [spec, setSpec] = useState<CostAttributionLabelNameListResponse<CostAttributionLabelName>>();
  const disableForm = true;

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Get Label from API
  useEffect(() => {
    if (data && data.items.length && data.items[0].spec.labelName) {
      setMetricLabelValue(data.items[0].spec.labelName);
      setInitialLabel(data.items[0].spec.labelName);
    }
  }, [data, setMetricLabelValue, setInitialLabel]);

  // Save the spec from the API to the local state for the post call
  useEffect(() => {
    if (data && !spec) {
      setSpec(data);
    }
  }, [data, spec, setSpec, postMutate]);

  // Default value update react-hook-form with the metricLabel value once the api returns
  useEffect(() => {
    reset({ metricLabel: metricLabel });
  }, [metricLabel, reset]);

  // Error handling for the API calls
  useEffect(() => {
    if (IsPostError || IsPutError) {
      setAppError('Unable to fetch or update configuration label.');
    }
  }, [IsPostError, IsPutError, spec]);

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    // Handle the validation and submission of the form to App Platform
    if (!errors.metricLabel && spec && data.metricLabel !== metricLabel && data.metricLabel) {
      // Update the context with the new metricLabel value
      setMetricLabelValue(data.metricLabel);
      // This should never run since we disable the form if there is no initial label right now
      if (initialLabel) {
        // Update the API with PUT call in the label previously existed
        putMutate({ data: spec, labelName: data.metricLabel });
      } else {
        // Update the API with POST call if no initial label is found
        postMutate({ data: spec, labelName: data.metricLabel });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.tabsContainer} data-testid={testIds.configurationTab.container}>
        <h3>Cost attribution label</h3>
        <p className={styles.width50}>
          The label name by which you choose to attribute usage.
          <div>
            <a
              className="external-link"
              href="https://grafana.com/docs/grafana-cloud/cost-management-and-billing/generate-usage-attribution-report/"
            >
              Learn how to generate usage attribution reports.
            </a>
          </div>
        </p>
        {apiError && <Alert title={apiError} />}
        <FieldSet label="">
          <Field label="" description="">
            <Controller
              control={control}
              name="metricLabel"
              defaultValue={metricLabel}
              disabled={disableForm}
              rules={{
                required: 'Metric label is required',
                pattern: {
                  value: /^[a-zA-Z_][a-zA-Z0-9_]*$/,
                  message: 'Invalid label format',
                },
                validate: (value) => {
                  return value !== initialLabel || 'New label cannot be the same as the old label.';
                },
              }}
              render={({ field }) => (
                <Input
                  width={60}
                  data-testid={testIds.configurationTab.metricLabel}
                  label={`Metric label`}
                  placeholder={`E.g.: namespace`}
                  {...field}
                />
              )}
            />
          </Field>
          {errors && errors.metricLabel && (
            <FieldValidationMessage>{String(errors.metricLabel.message)}</FieldValidationMessage>
          )}
        </FieldSet>
      </div>
    </form>
  );
};
