import { useState, useEffect, useMemo } from 'react';
import { PanelData, dateTime, type TimeRange } from '@grafana/data';
import { createQueryRunner } from '@grafana/runtime';
import { UNATTRIBUTED_VALUE } from '../constants/constant';

export const useTotalBillPanelData = (orgId: string | null, timeRange: TimeRange) => {
  const [panelData, setPanelData] = useState<PanelData | undefined>(undefined);
  const runner = useMemo(() => createQueryRunner(), []);

  // Subscribe to runner and clean up on unmount
  useEffect(() => {
    const subscription = runner.get().subscribe(setPanelData);
    return () => {
      subscription.unsubscribe();
    };
  }, [runner]);

  // Destroy the runner on unmount
  useEffect(() => {
    return () => {
      runner.destroy();
    };
  }, [runner]);

  const queries = useMemo(
    () =>
      orgId
        ? [
            {
              refId: 'A',
              expr: `
            (avg without(monetary) (grafanacloud_org_total_overage{org_id="${orgId}"}))
            `,
              legendFormat: 'Total Cost',
            },
          ]
        : [],
    [orgId]
  );

  useEffect(() => {
    if (queries.length) {
      runner.run({
        queries,
        datasource: { uid: 'grafanacloud-usage' },
        maxDataPoints: 500,
        minInterval: null,
        timeRange,
        timezone: 'utc',
      });
    }
  }, [queries, runner, timeRange]);

  return panelData;
};

export const useMetricTotalBillPanelData = (metricsTenantId: string | null, timeRange: TimeRange) => {
  const [metricPanelData, setMetricPanelData] = useState<PanelData | undefined>(undefined);
  const runner = useMemo(() => createQueryRunner(), []);

  // Subscribe to runner and clean up on unmount
  useEffect(() => {
    const subscription = runner.get().subscribe(setMetricPanelData);
    return () => {
      subscription.unsubscribe();
    };
  }, [runner]);

  // Destroy the runner on unmount
  useEffect(() => {
    return () => {
      runner.destroy();
    };
  }, [runner]);

  const queries = useMemo(
    () =>
      metricsTenantId
        ? [
            {
              refId: 'A',
              expr: `
             max(grafanacloud_instance_billable_usage{id="${metricsTenantId}"}) by (org_id)
      / on(org_id) group_right() max(grafanacloud_org_metrics_billable_series{}) by (org_id)
      * on(org_id,id,org_id,cluster) group_right() avg by(org_id) (grafanacloud_org_metrics_overage{})
            `,
              legendFormat: 'Metrics',
            },
          ]
        : [],
    [metricsTenantId]
  );

  useEffect(() => {
    if (queries.length) {
      runner.run({
        queries,
        datasource: { uid: 'grafanacloud-usage' },
        maxDataPoints: 500,
        minInterval: null,
        timeRange,
        timezone: 'utc',
      });
    }
  }, [queries, runner, timeRange]);

  return metricPanelData;
};

export const useLogsStartTimestamp = () => {
  const [logsTimestamp, setLogsTimestamp] = useState<PanelData | undefined>(undefined);
  const runner = useMemo(() => createQueryRunner(), []);

  // Subscribe to runner and clean up on unmount
  useEffect(() => {
    const subscription = runner.get().subscribe(setLogsTimestamp);
    return () => {
      subscription.unsubscribe();
    };
  }, [runner]);

  // Destroy the runner on unmount
  useEffect(() => {
    return () => {
      runner.destroy();
    };
  }, [runner]);

  const queries = useMemo(
    () => [
      {
        refId: 'A',
        instant: true,
        expr: `
          min(min_over_time(timestamp(grafanacloud_logs_instance_attributed_bytes_received_per_second)[120d:]))
        `,
      },
    ],
    []
  );

  useEffect(() => {
    runner.run({
      queries,
      datasource: { uid: 'grafanacloud-usage' },
      maxDataPoints: 500,
      minInterval: null,
      timeRange: {
        from: dateTime(),
        to: dateTime(),
        raw: {
          from: 'now',
          to: 'now',
        },
      },
      timezone: 'utc',
    });
  }, [queries, runner]);

  return logsTimestamp;
};

export const useLogsTotalBillPanelData = (logsTenantId: string | null, timeRange: TimeRange) => {
  const [logPanelData, setLogPanelData] = useState<PanelData | undefined>(undefined);
  const runner = useMemo(() => createQueryRunner(), []);

  // Subscribe to runner and clean up on unmount
  useEffect(() => {
    const subscription = runner.get().subscribe(setLogPanelData);
    return () => {
      subscription.unsubscribe();
    };
  }, [runner]);

  // Destroy the runner on unmount
  useEffect(() => {
    return () => {
      runner.destroy();
    };
  }, [runner]);

  const queries = useMemo(
    () =>
      logsTenantId
        ? [
            {
              refId: 'A',
              expr: `
             max(grafanacloud_logs_instance_usage{id="${logsTenantId}"}) by (org_id) 
  / on(org_id) group_right() max(grafanacloud_org_logs_usage{}) by (org_id)
  * on(id,org_id,cluster) group_right() avg by(org_id) (grafanacloud_org_logs_overage{})
            `,
              legendFormat: 'Logs',
            },
          ]
        : [],
    [logsTenantId]
  );

  useEffect(() => {
    if (queries.length) {
      runner.run({
        queries,
        datasource: { uid: 'grafanacloud-usage' },
        maxDataPoints: 500,
        minInterval: null,
        timeRange,
        timezone: 'utc',
      });
    }
  }, [queries, runner, timeRange]);

  return logPanelData;
};

const LOG_METRIC = 'grafanacloud_logs_instance_attributed_bytes_received_per_second';
function nonZeroLogsUsageQuery(filter = '') {
  return `${LOG_METRIC}${filter} > 0  and ${LOG_METRIC}${filter} < +Inf`;
}
export const useLogsAttributionData = (attributionLabel: string | null, timeRange: TimeRange) => {
  const [logPanelData, setLogPanelData] = useState<PanelData | undefined>(undefined);
  const runner = useMemo(() => createQueryRunner(), []);

  // Subscribe to runner and clean up on unmount
  useEffect(() => {
    const subscription = runner.get().subscribe(setLogPanelData);
    return () => {
      subscription.unsubscribe();
    };
  }, [runner]);

  // Destroy the runner on unmount
  useEffect(() => {
    return () => {
      runner.destroy();
    };
  }, [runner]);

  const queries = useMemo(
    () =>
      attributionLabel
        ? [
            {
              refId: 'A',
              expr: `
            sum by ("${attributionLabel}")
              (sum_over_time(
                (
                  (
                    ${nonZeroLogsUsageQuery(`{${attributionLabel}=""}`)}
                  )
                  or
                  (
                    ${missingLabelReplace(nonZeroLogsUsageQuery(`{${attributionLabel}!=""}`), attributionLabel)}
                  )
                )[$__range:1m]
              ) * 60
            )
        `,
              instant: true,
            },
          ]
        : [],
    [attributionLabel]
  );

  useEffect(() => {
    if (queries.length) {
      runner.run({
        queries,
        datasource: { uid: 'grafanacloud-usage' },
        maxDataPoints: 500,
        minInterval: null,
        timeRange,
        timezone: 'utc',
      });
    }
  }, [queries, runner, timeRange]);

  return logPanelData;
};

export const useLogsAttributionDataTotal = (timeRange: TimeRange) => {
  const [logPanelData, setLogPanelData] = useState<PanelData | undefined>(undefined);
  const runner = useMemo(() => createQueryRunner(), []);

  // Subscribe to runner and clean up on unmount
  useEffect(() => {
    const subscription = runner.get().subscribe(setLogPanelData);
    return () => {
      subscription.unsubscribe();
    };
  }, [runner]);

  // Destroy the runner on unmount
  useEffect(() => {
    return () => {
      runner.destroy();
    };
  }, [runner]);

  const queries = useMemo(
    () => [
      {
        refId: 'A',
        expr: `
          sum by ()(sum_over_time((grafanacloud_logs_instance_attributed_bytes_received_per_second > 0 and grafanacloud_logs_instance_attributed_bytes_received_per_second < +Inf)[$__range:1m]) * 60)
        `,
        instant: true,
      },
    ],
    []
  );

  useEffect(() => {
    runner.run({
      queries,
      datasource: { uid: 'grafanacloud-usage' },
      maxDataPoints: 500,
      minInterval: null,
      timeRange,
      timezone: 'utc',
    });
  }, [queries, runner, timeRange]);

  return logPanelData;
};

export const useLogsBillingDataTotal = (logTenantId: string | null, timeRange: TimeRange) => {
  const [logPanelData, setLogPanelData] = useState<PanelData | undefined>(undefined);
  const runner = useMemo(() => createQueryRunner(), []);

  // Subscribe to runner and clean up on unmount
  useEffect(() => {
    const subscription = runner.get().subscribe(setLogPanelData);
    return () => {
      subscription.unsubscribe();
    };
  }, [runner]);

  // Destroy the runner on unmount
  useEffect(() => {
    return () => {
      runner.destroy();
    };
  }, [runner]);

  const queries = useMemo(
    () =>
      logTenantId
        ? [
            {
              refId: 'A',
              expr: `
          grafanacloud_logs_instance_usage{id="${logTenantId}"}
        `,
              instant: true,
            },
          ]
        : [],
    [logTenantId]
  );

  useEffect(() => {
    if (queries.length) {
      runner.run({
        queries,
        datasource: { uid: 'grafanacloud-usage' },
        maxDataPoints: 500,
        minInterval: null,
        timeRange,
        timezone: 'utc',
      });
    }
  }, [queries, runner, timeRange]);

  return logPanelData;
};

export const useTracesStartTimestamp = () => {
  const [tracesTimestamp, setTracesTimestamp] = useState<PanelData | undefined>(undefined);
  const runner = useMemo(() => createQueryRunner(), []);

  // Subscribe to runner and clean up on unmount
  useEffect(() => {
    const subscription = runner.get().subscribe(setTracesTimestamp);
    return () => {
      subscription.unsubscribe();
    };
  }, [runner]);

  // Destroy the runner on unmount
  useEffect(() => {
    return () => {
      runner.destroy();
    };
  }, [runner]);

  const queries = useMemo(
    () => [
      {
        refId: 'A',
        instant: true,
        expr: `
          min(min_over_time(timestamp(grafanacloud_traces_instance_attributed_bytes_received_per_second)[120d:]))
        `,
      },
    ],
    []
  );

  useEffect(() => {
    runner.run({
      queries,
      datasource: { uid: 'grafanacloud-usage' },
      maxDataPoints: 500,
      minInterval: null,
      timeRange: {
        from: dateTime(),
        to: dateTime(),
        raw: {
          from: 'now',
          to: 'now',
        },
      },
      timezone: 'utc',
    });
  }, [queries, runner]);

  return tracesTimestamp;
};

export const useTracesTotalBillPanelData = (tracesTenantId: string | null, timeRange: TimeRange) => {
  const [tracesPanelData, setTracePanelData] = useState<PanelData | undefined>(undefined);
  const runner = useMemo(() => createQueryRunner(), []);

  // Subscribe to runner and clean up on unmount
  useEffect(() => {
    const subscription = runner.get().subscribe(setTracePanelData);
    return () => {
      subscription.unsubscribe();
    };
  }, [runner]);

  // Destroy the runner on unmount
  useEffect(() => {
    return () => {
      runner.destroy();
    };
  }, [runner]);

  const queries = useMemo(
    () =>
      tracesTenantId
        ? [
            {
              refId: 'A',
              expr: `
             max(grafanacloud_traces_instance_usage{id="${tracesTenantId}"}) by (org_id) 
  / on(org_id) group_right() max(grafanacloud_org_traces_usage{}) by (org_id)
  * on(id,org_id,cluster) group_right() avg by(org_id) (grafanacloud_org_traces_overage{})
            `,
              legendFormat: 'Traces',
            },
          ]
        : [],
    [tracesTenantId]
  );

  useEffect(() => {
    if (queries.length) {
      runner.run({
        queries,
        datasource: { uid: 'grafanacloud-usage' },
        maxDataPoints: 500,
        minInterval: null,
        timeRange,
        timezone: 'utc',
      });
    }
  }, [queries, runner, timeRange]);

  return tracesPanelData;
};

const TRACES_METRIC = 'grafanacloud_traces_instance_attributed_bytes_received_per_second';
function nonZeroTracesUsageQuery(filter = '') {
  return `${TRACES_METRIC}${filter} > 0  and ${TRACES_METRIC}${filter} < +Inf`;
}

export const useTracesAttributionData = (attributionLabel: string | null, timeRange: TimeRange) => {
  const [tracesPanelData, setTracesPanelData] = useState<PanelData | undefined>(undefined);
  const runner = useMemo(() => createQueryRunner(), []);

  // Subscribe to runner and clean up on unmount
  useEffect(() => {
    const subscription = runner.get().subscribe(setTracesPanelData);
    return () => {
      subscription.unsubscribe();
    };
  }, [runner]);

  // Destroy the runner on unmount
  useEffect(() => {
    return () => {
      runner.destroy();
    };
  }, [runner]);

  const queries = useMemo(
    () =>
      attributionLabel
        ? [
            {
              refId: 'A',
              expr: `
            sum by ("${attributionLabel}")(
              sum_over_time(
                (
                  ${nonZeroTracesUsageQuery(`{${attributionLabel}=""}`)}
                  or
                  ${missingLabelReplace(nonZeroTracesUsageQuery(`{${attributionLabel}!=""}`), attributionLabel)}
                )[$__range:1m]
              ) * 60
            )
        `,
              instant: true,
            },
          ]
        : [],
    [attributionLabel]
  );

  useEffect(() => {
    runner.run({
      queries,
      datasource: { uid: 'grafanacloud-usage' },
      maxDataPoints: 500,
      minInterval: null,
      timeRange,
      timezone: 'utc',
    });
  }, [queries, runner, timeRange]);

  return tracesPanelData;
};

export const useTracesAttributionDataTotal = (timeRange: TimeRange) => {
  const [tracesPanelData, setTracesPanelData] = useState<PanelData | undefined>(undefined);
  const runner = useMemo(() => createQueryRunner(), []);

  // Subscribe to runner and clean up on unmount
  useEffect(() => {
    const subscription = runner.get().subscribe(setTracesPanelData);
    return () => {
      subscription.unsubscribe();
    };
  }, [runner]);

  // Destroy the runner on unmount
  useEffect(() => {
    return () => {
      runner.destroy();
    };
  }, [runner]);

  const queries = useMemo(
    () => [
      {
        refId: 'A',
        expr: `
          sum by ()(sum_over_time((grafanacloud_traces_instance_attributed_bytes_received_per_second > 0  and grafanacloud_traces_instance_attributed_bytes_received_per_second < +Inf)[$__range:1m]) * 60)
        `,
        instant: true,
      },
    ],
    []
  );

  useEffect(() => {
    runner.run({
      queries,
      datasource: { uid: 'grafanacloud-usage' },
      maxDataPoints: 500,
      minInterval: null,
      timeRange,
      timezone: 'utc',
    });
  }, [queries, runner, timeRange]);

  return tracesPanelData;
};

export const useTracesBillingDataTotal = (tracesTenantId: string | null, timeRange: TimeRange) => {
  const [tracesPanelData, setTracesPanelData] = useState<PanelData | undefined>(undefined);
  const runner = useMemo(() => createQueryRunner(), []);

  // Subscribe to runner and clean up on unmount
  useEffect(() => {
    const subscription = runner.get().subscribe(setTracesPanelData);
    return () => {
      subscription.unsubscribe();
    };
  }, [runner]);

  // Destroy the runner on unmount
  useEffect(() => {
    return () => {
      runner.destroy();
    };
  }, [runner]);

  const queries = useMemo(
    () =>
      tracesTenantId
        ? [
            {
              refId: 'A',
              expr: `
          grafanacloud_traces_instance_usage{id="${tracesTenantId}"}
        `,
              instant: true,
            },
          ]
        : [],
    [tracesTenantId]
  );

  useEffect(() => {
    if (queries.length) {
      runner.run({
        queries,
        datasource: { uid: 'grafanacloud-usage' },
        maxDataPoints: 500,
        minInterval: null,
        timeRange,
        timezone: 'utc',
      });
    }
  }, [queries, runner, timeRange]);

  return tracesPanelData;
};

function missingLabelReplace(query: string, attributionLabel: string | null) {
  return `label_replace(
            ${query},
            "${attributionLabel}",
            "",
            "${attributionLabel}",
            "^${UNATTRIBUTED_VALUE}$"
          )`;
}
