import type { DataFrame } from '@grafana/data';

export const UNATTRIBUTED = 'unattributed';
export const UNATTRIBUTED_VALUE = '__missing__';
export const BILLABLE_SERIES = 'BillableSeries';
export const DOLLAR_COST = 'dollarCost';
export const MONTH_ISO = 'Month(ISO)';
export const FIELD_NAME_BILLABLE_METRIC = 'BillableSeries (sum) (first)';
export const FIELD_NAME_VALUE = 'Value';
export const FIELD_NAME_BILLABLE_SERIES = (value: string) => {
  return `${value} Billable series`;
};
export const FIELD_NAME_BYTES = (value: string, isPartialData: boolean) => {
  return `${value} ${isPartialData ? '(partial)' : '(volume)'}`;
};
export const FIELD_NAME_APPROXIMATE_COST = (value: string) => {
  return `${value} cost`;
};

export const TABLE_COLUMNS = {
  Metrics: 'Metrics cost',
  Logs: 'Logs cost',
  Traces: 'Traces cost',
  Total: 'Total cost',
};

export const LOADING_TABLE_DATA = [
  [
    {
      fields: [
        {
          name: 'label',
          type: 'string',
          config: {},
          values: [],
        },
        {
          name: 'BillableSeries (sum) (first)',
          values: [],
          type: 'number',
          config: {
            displayName: 'Metrics (billable series)',
          },
        },
        {
          name: 'Metrics cost',
          type: 'number',
          config: {},
          values: [],
          state: {},
        },
        {
          name: 'Logs (volume)',
          type: 'number',
          config: {},
          values: [],
          state: {},
        },
        {
          name: 'Logs cost',
          type: 'number',
          config: {},
          values: [],
          state: {},
        },
        {
          name: 'Traces (volume)',
          type: 'number',
          config: {},
          values: [],
          state: {},
        },
        {
          name: 'Traces cost',
          type: 'number',
          config: {},
          values: [],
          state: {},
        },
        {
          name: 'Total cost',
          type: 'number',
          config: {},
          values: [],
          state: {},
        },
      ],
      length: 0,
    } as DataFrame,
  ],
];
