import React, { ReactNode, useMemo } from 'react';

import { PluginPage } from '@grafana/runtime';
import { NavModelItem, PageLayoutType } from '@grafana/data';
import { Tab, TabsBar } from '@grafana/ui';

import { ROUTES } from 'constants/routes';
import { prefixRoute } from 'utils/utils.routing';
import { capitalize } from 'lodash';
import { useCurrentPage } from 'hooks/routes';

const TabsWrapper = () => {
  const currentPage = useCurrentPage();

  return (
    <TabsBar>
      <Tab
        key={`tab__${ROUTES.Overview}`}
        label={capitalize(ROUTES.Overview)}
        href={prefixRoute(`${ROUTES.Overview}`)}
        active={currentPage === ROUTES.Overview}
      />
      <Tab
        key={`tab__${ROUTES.Metrics}`}
        label={capitalize(ROUTES.Metrics)}
        href={prefixRoute(`${ROUTES.Metrics}`)}
        active={currentPage === ROUTES.Metrics}
      />
      <Tab
        key={`tab__${ROUTES.Configuration}`}
        label={capitalize(ROUTES.Configuration)}
        href={prefixRoute(`${ROUTES.Configuration}`)}
        active={currentPage === ROUTES.Configuration}
      />
    </TabsBar>
  );
};

type Props = {
  children: ReactNode;
  logo: string;
};

export default function PageWrapper({ logo, children }: Props): React.JSX.Element {
  const pageNav = useMemo(
    () =>
      ({
        text: 'Attributions',
        img: logo,
        subTitle: (<>Segment and categorize usage within a stack </>) as ReactNode,
      } as NavModelItem),
    [logo]
  );

  return (
    <PluginPage pageNav={pageNav} layout={PageLayoutType.Standard}>
      <TabsWrapper />
      {children}
    </PluginPage>
  );
}
