import { BILLABLE_SERIES, MONTH_ISO } from 'constants/constant';

type FixedLabelAttribution = {
  [MONTH_ISO]: string; // 'YYYY-MM'
  [BILLABLE_SERIES]: number;
  _InstanceID: number;
  _OrgID: number;
  // The `cast: true` option in the csv-parse library doesn't seem to cast booleans
  _HasDpmPerSeriesLargerThanIncluded: 'true' | 'false'; // boolean
  _P95Time: string; // datetime
  _SumOfAttributions: number;
  _BillableSeriesAtP95Time: number;
  _BillableSeriesBilled: number;
};
export type FixedLabel = keyof FixedLabelAttribution;

type DimLabelAttribution = {
  [key in `label_${string}`]: string;
};
export type DimLabel = keyof DimLabelAttribution;
export const isDimLabel = (s: string): s is DimLabel => s.startsWith('label_');

export type Label = DimLabel | FixedLabel;

export type Attribution = {
  [key in Label]: string | number;
} & FixedLabelAttribution &
  DimLabelAttribution;

export type AttributionResponse = Attribution[] | { error: Error };

export interface CostAttributionLabelNameListResponse<T> {
  items: T[];
}
