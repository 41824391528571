import React, { useEffect, useRef, useState } from 'react';
import { SceneDataNode, SceneFlexLayout, EmbeddedScene, SceneFlexItem, PanelBuilders } from '@grafana/scenes';
import { LoadingState, DataFrame, ThresholdsMode, FieldType, type TimeRange } from '@grafana/data';
import { useStyles } from 'styles';
import { testIds } from 'components/testIds';
import { LOADING_TABLE_DATA, TABLE_COLUMNS } from 'constants/constant';
import {
  BarGaugeDisplayMode,
  BarGaugeValueMode,
  TableCellDisplayMode,
  TableCellBackgroundDisplayMode,
  TableCellHeight,
} from '@grafana/schema';

type Props = {
  attributionLabel: string | null;
  dataNodes: SceneDataNode[];
  dataFrames: DataFrame[][];
  showFooter?: boolean;
};

function getDataNodes(dataFramesLastMonth: DataFrame[][], loading: boolean, timeRange: TimeRange) {
  return dataFramesLastMonth.map((df, i) => {
    return new SceneDataNode({
      data: {
        state: loading ? LoadingState.Loading : LoadingState.Done,
        series: [df[0]],
        timeRange,
      },
    });
  });
}

export function buildSceneBody(attributionLabel: string | null, showFooter = true) {
  return PanelBuilders.table()
    .setTitle(`Cost attribution per ${attributionLabel} label`)
    .setDescription(
      'Dollar values presented in the table are allocated based on the total invoice amount and proportion of unit count.'
    )
    .setOption('showHeader', true)
    .setOption('cellHeight', TableCellHeight.Sm)
    .setOption('footer', { show: showFooter, reducer: ['sum'], countRows: false, fields: [] })
    .setCustomFieldConfig('align', 'auto')
    .setCustomFieldConfig('cellOptions', {
      type: TableCellDisplayMode.Auto,
      wrapText: false,
    })
    .setUnit('locale')
    .setCustomFieldConfig('inspect', false)
    .setThresholds({
      mode: ThresholdsMode.Percentage,
      steps: [
        {
          color: 'dark-blue',
          value: 0,
        },
        {
          color: 'semi-dark-blue',
          value: 10,
        },
        {
          color: 'blue',
          value: 30,
        },
        {
          color: 'light-blue',
          value: 40,
        },
        {
          color: 'dark-yellow',
          value: 50,
        },
        {
          color: 'dark-orange',
          value: 70,
        },
        {
          color: 'dark-red',
          value: 90,
        },
      ],
    })
    .setOverrides((builder) => {
      builder
        .matchFieldsWithNameByRegex(`/Logs/`)
        .overrideUnit('bytes')
        .matchFieldsWithNameByRegex(`/Traces/`)
        .overrideUnit('bytes')
        .matchFieldsWithNameByRegex(`/.* cost/`)
        .overrideUnit('currencyUSD')
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.Gauge,
          valueDisplayMode: BarGaugeValueMode.Text,
          mode: BarGaugeDisplayMode.Gradient,
        })
        .overrideMin(0)
        .overrideCustomFieldConfig('align', 'left')
        .matchFieldsByType(FieldType.string)
        .overrideCustomFieldConfig('filterable', true)
        .matchFieldsWithName(TABLE_COLUMNS.Total)
        .overrideMin(0)
        .overrideMax(1.69)
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorBackground,
          mode: TableCellBackgroundDisplayMode.Basic,
        });
    })
    .build();
}
export function getScene({ attributionLabel, dataNodes, dataFrames, showFooter }: Props) {
  const frames = dataFrames.map((df, i) => {
    return new SceneFlexItem({
      width: '100%',
      $data: dataNodes[i],
      height: 400,
      body: buildSceneBody(attributionLabel, showFooter),
    });
  });

  return new EmbeddedScene({
    body: new SceneFlexLayout({
      children: [...frames],
    }),
  });
}

type TableSceneProps = {
  attributionLabel: string | null;
  dataFramesLastMonth: DataFrame[][];
  loading: boolean;
  timeRange: TimeRange;
};

export const TableScene = ({ attributionLabel, dataFramesLastMonth, loading, timeRange }: TableSceneProps) => {
  const styles = useStyles();
  const firstLoad = useRef(true);
  const hasFooter = dataFramesLastMonth !== LOADING_TABLE_DATA;
  const [dataNodes, setDataNodes] = useState(getDataNodes(dataFramesLastMonth, loading, timeRange));
  const [scene, setScene] = useState(
    getScene({
      attributionLabel,
      dataNodes,
      dataFrames: dataFramesLastMonth,
      showFooter: hasFooter,
    })
  );

  useEffect(() => {
    if (!firstLoad.current) {
      const initDataNodes = getDataNodes(dataFramesLastMonth, loading, timeRange);
      setDataNodes(initDataNodes);
      setScene(
        getScene({
          attributionLabel,
          dataNodes: initDataNodes,
          dataFrames: dataFramesLastMonth,
          showFooter: hasFooter,
        })
      );
    }
    firstLoad.current = false;
    // We do not want to run this effect everytime the dataFrames change, only when it changes from empty to non-empty
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributionLabel, hasFooter]);

  useEffect(() => {
    dataNodes.forEach((node, i) => {
      node.setState({
        data: {
          state: loading ? LoadingState.Loading : LoadingState.Done,
          series: [dataFramesLastMonth[i][0]],
          timeRange,
        },
      });
    });
  }, [dataFramesLastMonth, dataNodes, loading, timeRange]);

  return (
    <div className={styles.overviewTableGrid} data-testid={testIds.overviewTab.table}>
      <scene.Component model={scene} />
    </div>
  );
};
