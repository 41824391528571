import { dateTime, type DataFrame, type TimeRange } from '@grafana/data';
import {
  useLogsAttributionData,
  useLogsAttributionDataTotal,
  useLogsBillingDataTotal,
  useLogsStartTimestamp,
  useLogsTotalBillPanelData,
  useMetricTotalBillPanelData,
  useTotalBillPanelData,
  useTracesAttributionData,
  useTracesAttributionDataTotal,
  useTracesBillingDataTotal,
  useTracesStartTimestamp,
  useTracesTotalBillPanelData,
} from '../../../hooks/overview';
import { checkPartialData } from '../../../utils/utils.date';
import { useEffect, useMemo, useState } from 'react';
import { useGetLabels } from '../../../hooks/configuration';
import { filterSelectedMonth, formatTransformDollars } from '../../../utils/utils.formating';
import { emptyBillableDataframe } from '../../../utils/utils.defaults';
import { convertAttributionsToDataFrame } from '../../../utils/utils.customDataSource';
import type { Attribution } from '../../../types';
import { createCSV } from '../../../utils/utils.csv';
import { useGetRecords } from '../../../hooks/attributions';
import {
  checkEmptyDataSeries as isEmptyDataSeries,
  doneStateCheck,
  loadingStateCheck,
} from '../../../utils/utils.panels';
import { LOADING_TABLE_DATA } from '../../../constants/constant';

export function useIsFullMonthReport(timeRange: TimeRange) {
  // Get Logs Start Timestamp
  const logsStartTimestamp = useLogsStartTimestamp();
  const logsStartValues = logsStartTimestamp?.series[0]?.fields.filter((field) => field.name === 'Value')[0];
  const logsStartValue = logsStartValues ? logsStartValues.values[0] : null;

  // Get Traces Start Timestamp
  const tracesStartTimestamp = useTracesStartTimestamp();
  const tracesStartValues = tracesStartTimestamp?.series[0]?.fields.filter((field) => field.name === 'Value')[0];
  const tracesStartValue = tracesStartValues ? tracesStartValues.values[0] : null;

  let fullDataStartMonth = '';
  let fullCoverageLaggingMonth = '';
  const logPartialDataCheck = checkPartialData(logsStartValue, timeRange);
  const tracesPartialDataCheck = checkPartialData(tracesStartValue, timeRange);
  const fullBillingMonth = tracesPartialDataCheck.fullBillingMonth.isBefore(logPartialDataCheck.fullBillingMonth)
    ? dateTime(logPartialDataCheck.fullBillingMonth)
    : dateTime(tracesPartialDataCheck.fullBillingMonth);

  const isLogsPartial = logPartialDataCheck.partialData;
  const isTracesPartial = tracesPartialDataCheck.partialData;

  if (isLogsPartial || isTracesPartial) {
    fullDataStartMonth = fullBillingMonth.format('MMMM D');
    fullCoverageLaggingMonth = dateTime(fullBillingMonth).subtract(1, 'month').format('MMMM yyyy');
  }
  const isPartialData = useMemo(
    () => ({
      isLogsPartial,
      isTracesPartial,
      noLogsData: logPartialDataCheck.noDataThisMonth,
      noTracesData: tracesPartialDataCheck.noDataThisMonth,
      noTimestamps: logPartialDataCheck.noTimestamp && tracesPartialDataCheck.noTimestamp,
    }),
    [
      isLogsPartial,
      isTracesPartial,
      logPartialDataCheck.noDataThisMonth,
      tracesPartialDataCheck.noDataThisMonth,
      tracesPartialDataCheck.noTimestamp,
      logPartialDataCheck.noTimestamp,
    ]
  );

  return {
    isPartialData,
    fullDataStartMonth,
    fullCoverageLaggingMonth,
    timestampsDoneLoading: doneStateCheck([logsStartTimestamp, tracesStartTimestamp]),
  };
}

type OrgConfig = {
  metricsTenantId: string;
  logsTenantId: string;
  tracesTenantId: string;
  orgId: string;
  attributionLabel: string | null;
  error?: Error | null;
};
export function useGetOrgConfiguration() {
  // Use App Platform to get instance ids & configured label
  const { data: costAttributionLabelData, error } = useGetLabels();

  const [orgSettings, setOrgSettings] = useState<OrgConfig>({
    metricsTenantId: '',
    logsTenantId: '',
    tracesTenantId: '',
    orgId: '',
    attributionLabel: null,
    error,
  });

  useEffect(() => {
    const item = costAttributionLabelData?.items[0];
    setOrgSettings({
      metricsTenantId: item?.metadata?.labels?.['hm-instance-id'] || '',
      logsTenantId: item?.metadata?.labels?.['hl-instance-id'] || '',
      tracesTenantId: item?.metadata?.labels?.['ht-instance-id'] || '',
      orgId: item?.metadata?.labels?.['org-id'] || '',
      attributionLabel: item?.spec?.labelName || null,
      error,
    });
  }, [costAttributionLabelData, error]);

  return orgSettings;
}

export type PartialData = {
  isLogsPartial: boolean;
  isTracesPartial: boolean;
  noLogsData: boolean;
  noTracesData: boolean;
  noTimestamps: boolean;
};

export function useGetData(
  orgConfig: OrgConfig,
  timeRange: TimeRange,
  isCurrentMonth: boolean,
  isPartialData: PartialData,
  timestampsDoneLoading: boolean
) {
  const { metricsTenantId, logsTenantId, tracesTenantId, orgId, attributionLabel } = orgConfig;
  // Get Total Costs for each product (top stat panel totals)
  const totalBillPanelData = useTotalBillPanelData(orgId, timeRange);
  const metricTotalBillPanelData = useMetricTotalBillPanelData(metricsTenantId, timeRange);
  const logsTotalBillPanelData = useLogsTotalBillPanelData(logsTenantId, timeRange);
  const tracesTotalBillPanelData = useTracesTotalBillPanelData(tracesTenantId, timeRange);

  // Attribution data
  const logsAttributionData = useLogsAttributionData(attributionLabel, timeRange);
  const tracesAttributionData = useTracesAttributionData(attributionLabel, timeRange);

  // Get total usage and cost so that we can update the values to be proportinal
  const logsBillingDataTotal = useLogsBillingDataTotal(logsTenantId, timeRange);
  const logsAttributionDataTotal = useLogsAttributionDataTotal(timeRange);
  const tracesBillingDataTotal = useTracesBillingDataTotal(tracesTenantId, timeRange);
  const tracesAttributionDataTotal = useTracesAttributionDataTotal(timeRange);

  // Get Attribution csv report
  const { data: allMetricAttributions, isLoading: metricsLoading, isError: attributionsError } = useGetRecords();
  const [dataFrames, setDataFrames] = useState<DataFrame[][]>(LOADING_TABLE_DATA);
  const [metricAttributions, setMetricAttributions] = useState<Attribution[] | null>(null);
  const [metricAttributionsFrame, setMetricAttributionsFrame] = useState<DataFrame | null>(null);
  const [CSVdata, setCSVdata] = useState<string | null>(null);
  const noLogs = isEmptyDataSeries(logsAttributionData);
  const noTraces = isEmptyDataSeries(tracesAttributionData);
  const noAttributions = !metricAttributions?.length && noLogs && noTraces;
  const logsOrTracesLoading = loadingStateCheck([logsAttributionData, tracesAttributionData], timeRange);

  // Metric Attributions format data to last month and convert to DataFrame
  useEffect(() => {
    if (allMetricAttributions && allMetricAttributions.length > 0) {
      // Redact data to the last month
      const dataForMonth = filterSelectedMonth(allMetricAttributions, timeRange.to);
      const frame =
        isCurrentMonth && attributionLabel
          ? emptyBillableDataframe(attributionLabel)
          : convertAttributionsToDataFrame(dataForMonth);

      setMetricAttributions(dataForMonth);
      setMetricAttributionsFrame(frame);
    }
  }, [allMetricAttributions, timeRange, attributionLabel, isCurrentMonth]);

  // Format transform metrics, logs, traces and create csv
  useEffect(() => {
    const formatData = async () => {
      // Transform data to csv and table format with dollars, short, unattributed
      const { tableData, csvData } = await formatTransformDollars({
        metricAttributions: metricAttributions!,
        metricAttributionsFrame: metricAttributionsFrame!,
        metricTotalBillPanelData,
        logsTotalBillPanelData: logsTotalBillPanelData!,
        logsAttributionData: logsAttributionData!,
        logsAttributionDataTotal: logsAttributionDataTotal!,
        logsBillingDataTotal: logsBillingDataTotal!,
        tracesTotalBillPanelData: tracesTotalBillPanelData!,
        tracesAttributionData: tracesAttributionData!,
        tracesAttributionDataTotal: tracesAttributionDataTotal!,
        tracesBillingDataTotal: tracesBillingDataTotal!,
        isPartialData,
        isCurrentMonth,
        attributionLabel: attributionLabel!,
      });

      // Set Table data
      setDataFrames(tableData);
      // Set CSV data
      let combinedCSV = createCSV(csvData);
      setCSVdata(combinedCSV);
    };

    const doneLoading = doneStateCheck([
      metricTotalBillPanelData,
      logsTotalBillPanelData,
      logsAttributionData,
      logsAttributionDataTotal,
      logsBillingDataTotal,
      tracesTotalBillPanelData,
      tracesAttributionData,
      tracesAttributionDataTotal,
      tracesBillingDataTotal,
    ]);

    if (!noAttributions && attributionLabel && metricAttributionsFrame && doneLoading && timestampsDoneLoading) {
      formatData();
    } else if (noAttributions && doneLoading) {
      setDataFrames(LOADING_TABLE_DATA);
    }
  }, [
    metricAttributions,
    metricAttributionsFrame,
    metricTotalBillPanelData,
    logsTotalBillPanelData,
    logsAttributionData,
    logsAttributionDataTotal,
    logsBillingDataTotal,
    tracesTotalBillPanelData,
    tracesAttributionData,
    tracesAttributionDataTotal,
    tracesBillingDataTotal,
    isPartialData,
    isCurrentMonth,
    attributionLabel,
    noAttributions,
    timestampsDoneLoading,
  ]);

  return {
    dataFrames,
    CSVdata,
    attributionsLoading: orgConfig.error ? false : logsOrTracesLoading || metricsLoading,
    attributionsError:
      attributionsError || logsAttributionData?.errors || tracesAttributionData?.errors || orgConfig.error,
    noStats:
      metricAttributions && metricAttributions.length === 0 && !logsTotalBillPanelData && !tracesTotalBillPanelData,
    noAttributions,
    noAttributionsEver: !allMetricAttributions?.length && isPartialData.noTimestamps,
    noMetrics: !metricAttributions?.length,
    noTraces,
    totalBillPanelData,
    metricTotalBillPanelData,
    logsTotalBillPanelData,
    tracesTotalBillPanelData,
  };
}
