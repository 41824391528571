import React, { useEffect } from 'react';
import _ from 'lodash';
import { getScene } from './attributionsScenes';
import { useStyles } from 'styles';
import { useGetRecords } from 'hooks/attributions';
import { useGetLabels } from 'hooks/configuration';
import { usePluginMeta } from 'utils/utils.plugin';
import { registerCustomDatasource } from 'utils/utils.customDataSource';

export const AttributionsPage = () => {
  const styles = useStyles();
  const { data: metricLabels } = useGetLabels();
  const { data, isLoading } = useGetRecords();
  const metricLabel = metricLabels?.items?.[0]?.spec?.labelName || undefined;

  const pluginMeta = usePluginMeta();
  const hmTenantId = pluginMeta?.jsonData?.metricsTenantId ?? '';

  useEffect(() => {
    if (data && data.length > 0) {
      registerCustomDatasource(data);
    }
  }, [data]);

  const scene = getScene(data, metricLabel, isLoading, hmTenantId);

  return (
    <div className={styles.tabsContainer}>
      <scene.Component model={scene} />
    </div>
  );
};
