import type { DataFrame } from '@grafana/data';

export function emptyBillableDataframe(label: string) {
  return {
    fields: [
      {
        name: `Label: ${label}`,
        type: 'string',
        state: {},
        values: [] as string[],
        config: {},
      },
    ],
    length: 0,
  } as DataFrame;
}
