import React, { useState, useMemo } from 'react';
import { format, getYear } from 'date-fns';
import { type SelectableValue, type TimeRange } from '@grafana/data';
import { Button, Stack, Alert, useTheme2, Select } from '@grafana/ui';
import { testIds } from 'components/testIds';
import { useStyles } from 'styles';
import { ROUTES } from 'constants/routes';
import { StatsScene } from './StatsScene';
import { TableScene } from './TableScene';
import { GrotEmpty } from 'components/GrotEmpty/GrotEmpty';
import { downloadCSV } from 'utils/utils.csv';
import { prefixRoute } from 'utils/utils.routing';
import { attributionDateOptions, MAX_QUERYABLE_MONTHS, now } from '../../constants/date';
import { useHistory, useLocation } from 'react-router-dom';
import { useGetData, useGetOrgConfiguration, useIsFullMonthReport } from './hooks/overview-data';
import { createMonthRange } from '../../utils/utils.date';

export const OverviewPage = () => {
  const theme = useTheme2();
  const styles = useStyles();
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  const defaultMonth = searchParams.get('month') ? Number(searchParams.get('month')) : LAST_MONTH_INDEX;
  const [timeRangeIndex, setTimeRangeIndex] = useState(defaultMonth);

  // feature flag for additional months added to the date picker
  const ffMoreDateOptions = searchParams.get('ffMonths') ? Number(searchParams.get('ffMonths')) : MAX_QUERYABLE_MONTHS;
  const monthOptions = useMemo(() => {
    if (ffMoreDateOptions > MAX_QUERYABLE_MONTHS) {
      const extendedDateOptions = [...attributionDateOptions];
      for (let i = MAX_QUERYABLE_MONTHS + 1; i <= ffMoreDateOptions; i++) {
        extendedDateOptions.push(createMonthRange(i));
      }
      return extendedDateOptions;
    }
    return attributionDateOptions;
  }, [ffMoreDateOptions]);

  const timeRange = monthOptions[timeRangeIndex];
  const isCurrentMonth = timeRange.to.isSame(now);

  const { isPartialData, timestampsDoneLoading } = useIsFullMonthReport(timeRange);
  const orgConfig = useGetOrgConfiguration();
  const {
    dataFrames,
    CSVdata,
    noMetrics,
    noAttributionsEver,
    totalBillPanelData,
    metricTotalBillPanelData,
    logsTotalBillPanelData,
    tracesTotalBillPanelData,
    attributionsLoading,
    attributionsError,
  } = useGetData(orgConfig, timeRange, isCurrentMonth, isPartialData, timestampsDoneLoading);

  // Download CSV file
  const downLoadAttributions = () => {
    return () => {
      downloadCSV(CSVdata, 'attributions', timeRange);
    };
  };

  const onMonthChange = (val: SelectableValue<number>) => {
    setTimeRangeIndex(val.value!);
    searchParams.set('month', val.value!.toString());
    history.replace({ search: searchParams.toString() });
  };

  const titleDate = timeRange.from.format('MMMM yyyy');
  const pageTitle = `${titleDate} billing period`;

  const missingProductString = productNameList(noMetrics, isPartialData.noLogsData, isPartialData.noTracesData);
  const showMissingMetricsWarning = Boolean(!attributionsLoading && missingProductString.length);

  const partialProductString = productNameList(
    false,
    isPartialData.isLogsPartial && !isPartialData.noLogsData,
    isPartialData.isTracesPartial && !isPartialData.noTracesData
  );
  const showMissingLogOrTracesMetricsWarning = Boolean(
    !attributionsLoading && partialProductString.length && !attributionsLoading
  );

  const showCurrentMonthWarning = !attributionsLoading && isCurrentMonth && !showMissingLogOrTracesMetricsWarning;

  const showConfigureAttributions = !attributionsLoading && noAttributionsEver;

  return (
    <div className={styles.tabsContainer} data-testid={testIds.overviewTab.container}>
      <Stack direction={'column'} justifyContent="space-between">
        <div style={{ maxWidth: theme.spacing(32), marginBottom: theme.spacing(1) }}>
          <Select options={getDateOptions(monthOptions)} value={timeRangeIndex} onChange={onMonthChange} />
        </div>
        <Stack direction={'row'} justifyContent="space-between">
          <h2>{pageTitle}</h2>
          <Button
            disabled={!CSVdata}
            variant="secondary"
            fill="outline"
            icon="file-download"
            onClick={downLoadAttributions()}
          >
            Download (CSV)
          </Button>
        </Stack>
      </Stack>
      {showCurrentMonthWarning && (
        <Alert title="Month Incomplete" severity="info">
          Logs and Traces are available from the start of the month to today. Metrics are currently only calculated at
          the end of the month and therefore there is no data currently present.
        </Alert>
      )}
      {showMissingMetricsWarning && !showCurrentMonthWarning && (
        <Alert title="Missing attributions" severity="info">
          No {missingProductString} attribution data present for this month.
        </Alert>
      )}
      {showMissingLogOrTracesMetricsWarning && (
        <Alert title="Partial data detected" severity="info">
          We&apos;re detecting that cost attribution for {partialProductString} was enabled partway through this month,
          rather than from the month start. We&apos;re showing you the attribution usage over the partial period for
          which cost attribution was enabled. Because we do not have a full month of data, these volumes will not add up
          to the usage on your invoice for {titleDate}
        </Alert>
      )}
      <div>
        <div className={styles.overviewScene} data-testid={testIds.overviewTab.stats}>
          {/* Billing Panels */}
          <StatsScene
            totalBillPanelData={totalBillPanelData}
            metricTotalBillPanelData={metricTotalBillPanelData}
            logsTotalBillPanelData={logsTotalBillPanelData}
            tracesTotalBillPanelData={tracesTotalBillPanelData}
            timeRange={timeRange}
          />
        </div>
      </div>
      <div className={styles.overviewTableGrid}>
        {/* Attributions tables by labels */}
        {!attributionsLoading && attributionsError && <Alert title="An error occurred" />}
        {showConfigureAttributions && (
          <GrotEmpty
            description="Attributions have not been configured or are still processing. It can take up to one day to generate a report."
            cta={{ url: `${prefixRoute(ROUTES.Configuration)}`, text: 'Cost attributions configuration' }}
          />
        )}
        <TableScene
          attributionLabel={orgConfig.attributionLabel}
          dataFramesLastMonth={dataFrames}
          timeRange={timeRange}
          loading={attributionsLoading}
        />
      </div>
    </div>
  );
};

const LAST_MONTH_INDEX = 1;

function getDateOptions(ranges: TimeRange[]) {
  return ranges.map((month, i) => {
    let label = `${format(month.to.toDate(), 'MMMM')} ${getYear(month.to.toDate())}`;
    if (month.to.isSame(now)) {
      label += ' To Date';
    }
    return {
      label,
      value: i,
    };
  });
}

function productNameList(isMetrics: boolean, isLogs: boolean, isTraces: boolean) {
  const partialProduct = [];
  if (isMetrics) {
    partialProduct.push('metrics');
  }
  if (isLogs) {
    partialProduct.push('logs');
  }
  if (isTraces) {
    partialProduct.push('traces');
  }
  if (partialProduct.length > 2) {
    const last = partialProduct.pop();
    return `${partialProduct.join(', ')}, and ${last}`;
  }
  return partialProduct.join(' and ');
}
