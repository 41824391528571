import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppRootProps } from '@grafana/data';
import { PluginPropsContext } from 'utils/utils.plugin';
import { StoreContext } from 'context/StoreContext';
import { Routes } from 'components/Routes';
import PageWrapper from 'components/PageWrapper';
import { SceneContext } from 'context/SceneContext';

const queryClient = new QueryClient();
export class App extends React.PureComponent<AppRootProps> {
  render() {
    return (
      <PluginPropsContext.Provider value={this.props}>
        <StoreContext>
          <SceneContext>
            <QueryClientProvider client={queryClient}>
              <PageWrapper logo={this.props.meta.info.logos.large}>
                <Routes />
              </PageWrapper>
              <ReactQueryDevtools />
            </QueryClientProvider>
          </SceneContext>
        </StoreContext>
      </PluginPropsContext.Provider>
    );
  }
}
