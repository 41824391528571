import React, { createContext, ReactNode, useContext, useState } from 'react';

export type ConfigContextType = {
  metricLabel: string;
  setMetricLabelValue: React.Dispatch<React.SetStateAction<string>>;
};

export type LabelsType = {
  metricLabel: string;
};

export const initialState = {
  metricLabel: '',
  setMetricLabelValue: () => {},
};

type Props = {
  children: ReactNode[] | ReactNode;
};

export const ConfigContext = createContext<ConfigContextType>({
  metricLabel: '',
  setMetricLabelValue: () => {},
});

export const ConfigContextProvider = ({ children }: Props) => {
  const [metricLabel, setMetricLabelValue] = useState<string>('');

  const value = {
    metricLabel,
    setMetricLabelValue,
  };

  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};

export const useConfigContext = () => {
  return useContext(ConfigContext);
};
